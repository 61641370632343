<template>
	<div id="citrixlogin">
		<a href="https://citrix.affinitylogon.com/"
			>Affinity Desktop User Login</a
		>
	</div>
</template>

<script>
export default {
	name: "CitrixLogin",
};
</script>
