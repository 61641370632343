<template>
  <div class="login-underlay-header">
    <div class="companylogo">
      <!-- Affinity logo -->
      <img alt src="https://cdn.jsdelivr.net/gh/affinityteam/www-assets/v1/images/logo/affinity1_300_90.png" />
    </div>
    <div class="heading">Affinity Self Service</div>
    <div class="heading mobile">Self Service</div>

  </div>
</template>

<script>
export default {
  name: 'Header',
}
</script>