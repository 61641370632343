<script>
	import Setup from "./Setup.vue";
	export default {
		name: "SetupQuestions",
		props: ["questions", "mfaNotSet", "otpChannels", "isMFAV2"],
		components: {
			Setup,
		},
		data() {
			return {
				error: "",
				loading: false,
				questionList: this.questions,
				redirectUrl: "",
				data: {},
				answers: {
					answer1: {
						count: "1",
						questionId: 1,
						value: "",
					},
					answer2: {
						count: "2",
						questionId: 1,
						value: "",
					},
					answer3: {
						count: "3",
						questionId: 1,
						value: "",
					},
				},
			};
		},
		methods: {
			handleFormSubmit() {
				let data = Object.assign({}, this.answers);
				data.redirectUrl = this.redirectUrl;
				this.error = "";
				let questionIds = [];
				for (var answer in this.answers) {
					if (this.answers.hasOwnProperty(answer)) {
						if (this.answers[answer].value == "") {
							this.error = "All answers must be filled out.";
							break;
						}
						if (questionIds.includes(this.answers[answer].questionId)) {
							this.error = "You need to select and answer three different questions.";
							break;
						}
						questionIds.push(this.answers[answer].questionId);
					}
				}
				if (this.error != "") return;
				this.loading = true;
				this.$ajax
					.post("/Setup/Questions", data)
					.then(async (resp) => {
						const {
							Error,
							ClientRedirect,
							RedirectUrl: redirectUrl,
							RedirectPage,
							EssRedirect,
						} = resp.data;
						if (typeof resp.data != "undefined") {
							if (Error != null) {
								this.error = Error;
							} else {
								let channels = this.otpChannels;
								if (!EssRedirect) {
									if (this.mfaNotSet == undefined && ClientRedirect) {
										const {authenticated, mfaRequired, otpChannels} = await this.$checkMFACookie(
											redirectUrl,
										);
										this.mfaNotSet = mfaRequired && !authenticated;
										channels = otpChannels;
									}
								}

								if (ClientRedirect && redirectUrl) {
									if (this.mfaNotSet) {
										this.$router.push({
											name: "MFA",
											params: {
												redirectUrl,
												otpChannels: channels || undefined,
											},
										});
									} else {
										if (!EssRedirect)
											await this.$ajax.post("/legacy/success", null, {withCredentials: true});
										window.location.href = redirectUrl;
									}
								} else if (RedirectPage) {
									const queryString = {redirectUrl: redirectUrl || undefined};
									let params = {
										mfaNotSet: this.mfaNotSet || undefined,
										otpChannels: channels,
										isMFAV2: this.isMFAV2,
									};
									this.$router.push({
										name: RedirectPage,
										params,
										query: queryString,
									});
								}
							}
						}
					})
					.catch((err) => {
						if (err.status == 401) {
							this.$router.push({
								name: "Login",
								query: this.redirectUrl ? {redirectUrl: this.redirectUrl} : undefined,
							});
						}
					})
					.then(() => {
						this.loading = false;
					});
			},
			populatePage() {
				this.loading = true;
				this.$ajax
					.get("/Setup/Questions")
					.then((resp) => {
						if (typeof resp.data != "undefined") {
							if (resp.data.Error != null) {
								this.error = resp.data.Error;
							} else if (resp.data.Questions != null) {
								this.questionList = resp.data.Questions;
							}
						}
					})
					.catch(() => {})
					.then(() => {
						this.loading = false;
					});
			},
		},
		mounted() {
			if (this.questionList == null) {
				this.populatePage();
			}
			if (
				typeof this.$route.query != "undefined" &&
				typeof this.$route.query.redirectUrl != "undefined"
			) {
				this.redirectUrl = this.$route.query.redirectUrl;
			}
			// this.$reformatUrl("SetupQuestions");
		},
	};
</script>

<template>
	<setup title="Setup Security Questions" :error="error">
		<form
			class="card-body"
			method="post"
			id="form-questions-setup"
			@submit.prevent="handleFormSubmit">
			<div class="default-form w-border" style="text-align: left; margin: auto">
				<div v-for="answer in answers" style="display: inline-block" v-bind:key="answer">
					<div class="title-row section-title">Question {{ answer.count }}</div>
					<div class="form-row">
						<select class="long" v-model="answer.questionId">
							<option
								v-for="question in questionList"
								:value="question.Value"
								v-bind:key="question">
								{{ question.Text }}
							</option>
						</select>
					</div>
					<div class="form-row">
						<input type="text" v-model="answer.value" class="long" />
					</div>
				</div>
				<div class="form-row" style="text-align: center">
					<div class="mt-4">
						<div class="spinner small light" v-show="loading"></div>
						<input
							type="submit"
							value="Continue"
							class="button large blue"
							v-show="!loading"
							v-cloak />
					</div>
				</div>
			</div>
		</form>
	</setup>
</template>
