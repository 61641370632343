<script>
	import Vue from "vue";
	let AppIcons = Vue.extend({
		name: "AppIcons",
		props: ["asset"],
	});
	export default AppIcons;
</script>
<style scoped>
	span.app-icon {
		display: inline-block;
		font-size: 16px;
		line-height: 1em;
	}
</style>
<template>
	<span class="app-icon">
		<svg
			v-if="asset == 'check-circle'"
			viewBox="64 64 896 896"
			focusable="false"
			data-icon="check-circle"
			width="1em"
			height="1em"
			fill="currentColor"
			aria-hidden="true">
			<path
				d="M699 353h-46.9c-10.2 0-19.9 4.9-25.9 13.3L469 584.3l-71.2-98.8c-6-8.3-15.6-13.3-25.9-13.3H325c-6.5 0-10.3 7.4-6.5 12.7l124.6 172.8a31.8 31.8 0 0051.7 0l210.6-292c3.9-5.3.1-12.7-6.4-12.7z"></path>
			<path
				d="M512 64C264.6 64 64 264.6 64 512s200.6 448 448 448 448-200.6 448-448S759.4 64 512 64zm0 820c-205.4 0-372-166.6-372-372s166.6-372 372-372 372 166.6 372 372-166.6 372-372 372z"></path>
		</svg>
		<svg
			v-if="asset == 'close-circle'"
			fill-rule="evenodd"
			viewBox="64 64 896 896"
			focusable="false"
			data-icon="close-circle"
			width="1em"
			height="1em"
			fill="currentColor"
			aria-hidden="true">
			<path
				d="M512 64c247.4 0 448 200.6 448 448S759.4 960 512 960 64 759.4 64 512 264.6 64 512 64zm0 76c-205.4 0-372 166.6-372 372s166.6 372 372 372 372-166.6 372-372-166.6-372-372-372zm128.01 198.83c.03 0 .05.01.09.06l45.02 45.01a.2.2 0 01.05.09.12.12 0 010 .07c0 .02-.01.04-.05.08L557.25 512l127.87 127.86a.27.27 0 01.05.06v.02a.12.12 0 010 .07c0 .03-.01.05-.05.09l-45.02 45.02a.2.2 0 01-.09.05.12.12 0 01-.07 0c-.02 0-.04-.01-.08-.05L512 557.25 384.14 685.12c-.04.04-.06.05-.08.05a.12.12 0 01-.07 0c-.03 0-.05-.01-.09-.05l-45.02-45.02a.2.2 0 01-.05-.09.12.12 0 010-.07c0-.02.01-.04.06-.08L466.75 512 338.88 384.14a.27.27 0 01-.05-.06l-.01-.02a.12.12 0 010-.07c0-.03.01-.05.05-.09l45.02-45.02a.2.2 0 01.09-.05.12.12 0 01.07 0c.02 0 .04.01.08.06L512 466.75l127.86-127.86c.04-.05.06-.06.08-.06a.12.12 0 01.07 0z"></path>
		</svg>
		<svg
			v-if="asset == 'eye'"
			viewBox="64 64 896 896"
			focusable="false"
			data-icon="eye"
			width="1em"
			height="1em"
			fill="currentColor"
			aria-hidden="true">
			<path
				d="M942.2 486.2C847.4 286.5 704.1 186 512 186c-192.2 0-335.4 100.5-430.2 300.3a60.3 60.3 0 000 51.5C176.6 737.5 319.9 838 512 838c192.2 0 335.4-100.5 430.2-300.3 7.7-16.2 7.7-35 0-51.5zM512 766c-161.3 0-279.4-81.8-362.7-254C232.6 339.8 350.7 258 512 258c161.3 0 279.4 81.8 362.7 254C791.5 684.2 673.4 766 512 766zm-4-430c-97.2 0-176 78.8-176 176s78.8 176 176 176 176-78.8 176-176-78.8-176-176-176zm0 288c-61.9 0-112-50.1-112-112s50.1-112 112-112 112 50.1 112 112-50.1 112-112 112z"></path>
		</svg>
		<svg
			v-if="asset == 'eye-invisible'"
			viewBox="64 64 896 896"
			focusable="false"
			data-icon="eye-invisible"
			width="1em"
			height="1em"
			fill="currentColor"
			aria-hidden="true">
			<path
				d="M942.2 486.2Q889.47 375.11 816.7 305l-50.88 50.88C807.31 395.53 843.45 447.4 874.7 512 791.5 684.2 673.4 766 512 766q-72.67 0-133.87-22.38L323 798.75Q408 838 512 838q288.3 0 430.2-300.3a60.29 60.29 0 000-51.5zm-63.57-320.64L836 122.88a8 8 0 00-11.32 0L715.31 232.2Q624.86 186 512 186q-288.3 0-430.2 300.3a60.3 60.3 0 000 51.5q56.69 119.4 136.5 191.41L112.48 835a8 8 0 000 11.31L155.17 889a8 8 0 0011.31 0l712.15-712.12a8 8 0 000-11.32zM149.3 512C232.6 339.8 350.7 258 512 258c54.54 0 104.13 9.36 149.12 28.39l-70.3 70.3a176 176 0 00-238.13 238.13l-83.42 83.42C223.1 637.49 183.3 582.28 149.3 512zm246.7 0a112.11 112.11 0 01146.2-106.69L401.31 546.2A112 112 0 01396 512z"></path>
			<path
				d="M508 624c-3.46 0-6.87-.16-10.25-.47l-52.82 52.82a176.09 176.09 0 00227.42-227.42l-52.82 52.82c.31 3.38.47 6.79.47 10.25a111.94 111.94 0 01-112 112z"></path>
		</svg>
		<svg
			xmlns="http://www.w3.org/2000/svg"
			v-if="asset == 'loader'"
			height="5em"
			width="5em"
			data-icon="loader"
			viewBox="0 0 200 200">
			<radialGradient
				id="a3"
				cx=".66"
				fx=".66"
				cy=".3125"
				fy=".3125"
				gradientTransform="scale(1.5)">
				<stop offset="0" stop-color="#1268B3"></stop>
				<stop offset=".3" stop-color="#1268B3" stop-opacity=".9"></stop>
				<stop offset=".6" stop-color="#1268B3" stop-opacity=".6"></stop>
				<stop offset=".8" stop-color="#1268B3" stop-opacity=".3"></stop>
				<stop offset="1" stop-color="#1268B3" stop-opacity="0"></stop>
			</radialGradient>
			<circle
				transform-origin="center"
				fill="none"
				stroke="url(#a3)"
				stroke-width="15"
				stroke-linecap="round"
				stroke-dasharray="200 1000"
				stroke-dashoffset="0"
				cx="100"
				cy="100"
				r="70">
				<animateTransform
					type="rotate"
					attributeName="transform"
					calcMode="spline"
					dur="2"
					values="360;0"
					keyTimes="0;1"
					keySplines="0 0 1 1"
					repeatCount="indefinite"></animateTransform>
			</circle>
			<circle
				transform-origin="center"
				fill="none"
				opacity=".2"
				stroke="#1268B3"
				stroke-width="15"
				stroke-linecap="round"
				cx="100"
				cy="100"
				r="70"></circle>
		</svg>
	</span>
</template>
