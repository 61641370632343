export function setItemWithExpiry(key, value, ttl) {
    const now = new Date();

    // 'ttl' is the time to live in milliseconds
    const item = {
        value: value,
        expiry: now.getTime() + ttl,
    };

    localStorage.setItem(key, JSON.stringify(item));
}

export function getItemWithExpiry(key) {
    const itemStr = localStorage.getItem(key);
    // If the item doesn't exist, return null
    if (!itemStr) {
        return null;
    }

    const item = JSON.parse(itemStr);
    const now = new Date();

    // Compare the expiry time with the current time
    if (now.getTime() > item.expiry) {
        // If the item has expired, remove it from storage and return null
        localStorage.removeItem(key);
        return null;
    }

    return item.value;
}