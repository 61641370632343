<script>
  import Header from "@/components/Header";
  import Footer from "@/components/Footer";

  export default {
    name: "Setup",
    components: {
      Header,
      Footer,
    },
    props: ["title", "error", "hidePageTitle", "success", "onMounted"],
    mounted() {
      this.onMounted && this.onMounted();
    },
  };
</script>

<template>
  <div>
    <div class="login-underlay" id="login-underlay">
      <Header />
      <Footer />
    </div>

    <div class="absolut-full content errorpage">
      <div class="section setup-body">
        <div class="section-body">
          <div class="setup-content">
            <h1 v-show="!hidePageTitle" v-cloak>
              {{ title }}
            </h1>
            <div class="" v-cloak v-if="success">
              <p style="color: #7abd2d">{{ success }}</p>
            </div>
            <div class="" v-cloak v-if="error">
              <p v-html="error" style="color: red"></p>
            </div>
            <div class="">
              <slot></slot>
            </div>
            <!-- <div class="">
              <img id="alfred" alt :src="require('../assets/alfred1_357_355_no_bg.png')"/>
              <br />
              <br />
            </div> -->
          </div>
        </div>
      </div>
    </div>
    <!--		<div class="idserve-footer">-->
    <!--			&copy; Copyright {{ new Date().getFullYear() }} Affinity - -->
    <!--			<a href="http://www.affinityteam.co.nz/" target="affinity">Affinity Employer Services</a>-->
    <!--			<a href="http://www.affinityteam.co.nz/" target="affinity">-->
    <!--				<span class="idserve-footer-logo idserve-logo-base64"></span>-->
    <!--			</a>-->
    <!--			<span class="mobile hidden">(mobile)</span>-->
    <!--		</div>-->
  </div>
</template>
