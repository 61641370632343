<script>
	import Vue from "vue";
	import AppIcons from "./AppIcons.vue";

	let timer = null;
	let timerRetypePwd = null;
	let PasswordPage = Vue.extend({
		name: "PasswordPage",
		props: [
			"passwordVal",
			"confirmPasswordVal",
			"loading",
			"isMFAV2",
			"handleSubmit",
			"onValidationRequestError",
		],
		// setup() {
		// 	return {
		// 		isMFAV2: true,
		// 	};
		// },
		components: {
			AppIcons,
		},
		data() {
			return {
				output: {
					password: "",
					confirmPassword: "",
					passed: undefined,
				},
				hasLength: false,
				hasNumber: false,
				hasAlphaChars: false,
				hasSpecial: false,
				noIncrementingNumbers: false,
				noIdenticalChars: false,
				noReservedWords: false,
				notRepeatedPassword: false,
				hasConfirmed: undefined,

				showPwdGuide: false,
				showRpwdGuide: false,
				showPwd: false,
				showRePwd: false,
				outFocusMessage: "",
			};
		},
		watch: {
			"output.password": function () {
				this.outFocusMessage = "";
				if (this.isMFAV2) {
					const {password, confirmPassword} = this.output;
					if (password) {
						if (timer) {
							clearTimeout(timer);
							timer = null;
						}

						timer = setTimeout(async () => {
							this.hasLength = this.output.password.length >= 8;
							this.hasNumber = /\d/.test(this.output.password);
							this.hasAlphaChars =
								/[a-z]/.test(this.output.password) && /[A-Z]/.test(this.output.password);
							this.hasSpecial = /[!@#\$%\^\&*\)\(+=._-]/.test(this.output.password);

							const strArr = password.match(/.{1,1}/g);

							this.checkForIncrementingNumbers(strArr);
							this.checkForSameCharacters(strArr);
							await this.validatePassword();
							this.output.passed = ![
								this.hasAlphaChars,
								this.hasLength,
								this.hasNumber || this.hasSpecial,
								this.noIdenticalChars,
								this.noIncrementingNumbers,
								this.noReservedWords,
								this.notRepeatedPassword,
							].includes(false);
							if (confirmPassword) {
								this.hasConfirmed = password === confirmPassword;
							} else this.hasConfirmed = undefined;

							if (this.output.passed) {
								this.showPwdGuide = false;
							} else this.showPwdGuide = true;
						}, 300);
					} else {
						this.hasLength = false;
						this.hasNumber = false;
						this.hasAlphaChars = false;
						this.hasSpecial = false;
						this.noIdenticalChars = false;
						this.noIncrementingNumbers = false;
						this.noReservedWords = false;
						this.notRepeatedPassword = false;
						this.output.passed = undefined;
						this.output.confirmPassword = "";
						this.hasConfirmed = undefined;
					}
				} else this.output.passed = true;

				this.$emit("input", this.output);
			},
			"output.confirmPassword": function () {
				const {password, confirmPassword} = this.output;
				if (!password || !confirmPassword) {
					if (!confirmPassword) this.hasConfirmed = undefined;
					return false;
				}
				if (timerRetypePwd) {
					clearTimeout(timerRetypePwd);
					timerRetypePwd = null;
				}

				timerRetypePwd = setTimeout(() => {
					this.hasConfirmed = password === confirmPassword;
					if (this.hasConfirmed) {
						this.outFocusMessage = "";
					} else {
						this.outFocusMessage = "Password fields do not match.";
					}
					this.$emit("input", this.output);
				}, 250);
			},
		},
		methods: {
			handlePwdFocus() {
				if (!this.output.passed) {
					this.showPwdGuide = this.isMFAV2 ? true : false;
				}
			},
			handlePwdBlur() {
				if (this.isMFAV2) {
					this.showPwdGuide = false;
				}
			},
			toggleEye() {
				this.showPwd = !this.showPwd;
			},
			toggleEyeRePwd() {
				this.showRePwd = !this.showRePwd;
			},
			checkForIncrementingNumbers(strArr) {
				if (this.output.password && strArr.length >= 4) {
					this.noIncrementingNumbers = true;
					const nums = "0123456789";
					// Convert the password string to an array of characters
					strArr.forEach((_, index) => {
						const partialStr = strArr.slice(index, index + 4).join("");
						// Check if the next character is the next number in the sequence
						if (nums.includes(partialStr) && index + 2 < strArr.length) {
							this.noIncrementingNumbers = false;
						}
					});
				}
			},
			checkForSameCharacters(strArr) {
				if (this.output.password && strArr.length >= 3) {
					this.noIdenticalChars = true;
					let count = 0,
						mainChar = "";
					strArr.forEach((char) => {
						if (char === mainChar) {
							count++;
						} else {
							count = 1;
							mainChar = char;
						}

						if (count > 2) {
							this.noIdenticalChars = false;
						}
					});
				}
			},
			async validatePassword() {
				const {password} = this.output;
				// Run the validation only if password is at least 4 characters long
				if (password && password.length >= 4) {
					await this.$ajax
						.post("/Password/validate", {password: btoa(password)})
						.then((res) => {
							const {result, ValidationErrors} = res.data;
							const keys = Object.keys(ValidationErrors);
							let message = "";
							keys.forEach((key) => {
								if ((message = ValidationErrors[key].Message)) {
									return false;
								}
							});
							this.outFocusMessage = result === "FAILED" ? message : "";
							this.noReservedWords = ValidationErrors.NoInvalidWords.Success;
							this.notRepeatedPassword = ValidationErrors.NotRepeatedPassword.Success;
						})
						.catch((err) => {
							this.onValidationRequestError(err);
						});
				}
			},
			handleEnterKey(e) {
				if (e.key === "Enter") {
					this.handleSubmit();
				}
			},
		},
	});
	Vue.component("passwordPage", PasswordPage);
	export default PasswordPage;
</script>

<style scoped>
	.newui .section .default-form .form-row input.long {
		max-width: 100%;
		width: 100%;
	}

	.reset-pwd-page {
		padding: 20px 0;
	}

	.password-ctrl-input {
		position: relative;
		z-index: 8;
	}

	.password-ctrl-input.repwd {
		z-index: 7;
	}

	.password-ctrl-input.pwd > input {
		position: relative;
		z-index: 11;
	}

	.password-ctrl-guide {
		position: absolute;
		top: calc(100% - 8px);
		left: 0;
		right: 0;
		border-bottom-left-radius: 10px;
		border-bottom-right-radius: 10px;
		min-height: 60px;
		overflow: hidden;
		box-shadow: 0 5px 10px rgba(0, 0, 0, 0.05);
		background-color: #fff;
		z-index: 10;
		margin-left: -12px;
		margin-right: -12px;
	}

	.password-ctrl-guide > div {
		padding: 12px 20px 8px;
	}

	ul.password-guide-list {
		margin: 0;
		padding-left: 0;
		list-style: none;
	}

	ul.password-guide-list > li {
		padding-left: 20px;
		position: relative;
		line-height: 1.5;
	}

	span.password-ctrl-icon {
		background-size: cover;
		background-position: center;
		position: absolute;
		width: 14px;
		height: 14px;
	}

	span.eye-toggle {
		position: absolute;
		right: 14px;
		top: 14px;
		font-size: 16px;
		cursor: pointer;
		z-index: 11;
	}

	span.eye-toggle.valid-pwd {
		color: #4caf50;
	}

	span.eye-toggle.invalid-pwd {
		color: #f44336;
	}

	ul.password-guide-list > li span.validation-ico {
		left: 0;
		top: 2px;
	}

	ul.password-guide-list > li span.app-icon {
		font-size: 14px;
		color: #eb0029;
	}

	ul.password-guide-list > li.validation--passed {
		color: #bababa;
	}

	ul.password-guide-list > li.validation--passed span.app-icon {
		color: #0fa140;
	}

	.password-ctrl-guide ul li {
		line-height: 1.75;
	}

	ul.pwd-error {
		position: absolute;
		bottom: 100%;
		right: 0;
		left: 0;
		background-color: #fff4f4;
		border-radius: 10px;
		padding: 8px 12px;
		margin-bottom: 8px;
	}

	ul.pwd-error li {
		font-size: 10px;
	}
	ul.pwd-error > li span.validation-ico {
		top: 0;
	}

	.newui button.blue:disabled {
		background: #f4f4f4;
		color: #bbbbbb;
		cursor: not-allowed;
	}
</style>

<template>
	<div class="default-form w-border" style="text-align: left; margin: auto">
		<div class="form-row password-ctrl-input pwd">
			<input
				maxlength="50"
				placeholder="Enter your new password"
				v-model="output.password"
				@keydown="handleEnterKey"
				@focus="handlePwdFocus"
				@blur="handlePwdBlur"
				v-bind:type="showPwd ? 'text' : 'password'"
				autocomplete="off"
				class="long" />
			<span
				:class="{
					'eye-toggle': true,
					'valid-pwd': output.passed == true,
					'invalid-pwd': output.passed == false,
				}"
				@mousedown="showPwd = true"
				@mouseup="showPwd = false"
				@mouseleave="showPwd = false">
				<appIcons asset="eye-invisible" v-if="!showPwd" />
				<appIcons asset="eye" v-if="showPwd" />
			</span>
			<div class="password-ctrl-guide" v-show="showPwdGuide">
				<div>
					<ul class="password-guide-list">
						<li :class="{'validation--passed': hasLength}">
							<span class="password-ctrl-icon validation-ico">
								<appIcons asset="close-circle" v-if="!hasLength" v-cloak />
								<appIcons asset="check-circle" v-if="hasLength" v-cloak />
							</span>
							Must contain at least 8 characters.
						</li>
						<li :class="{'validation--passed': hasAlphaChars}">
							<span class="password-ctrl-icon validation-ico">
								<appIcons asset="close-circle" v-if="!hasAlphaChars" v-cloak />
								<appIcons asset="check-circle" v-if="hasAlphaChars" v-cloak
							/></span>
							Must contain at least one UPPERCASE and one lowercase character.
						</li>
						<li :class="{'validation--passed': hasNumber || hasSpecial}">
							<span class="password-ctrl-icon validation-ico">
								<appIcons asset="close-circle" v-if="!(hasNumber || hasSpecial)" v-cloak />
								<appIcons asset="check-circle" v-if="hasNumber || hasSpecial" v-cloak
							/></span>
							Must contain at least one number or special character (@#$%&*!).
						</li>
						<li :class="{'validation--passed': noIdenticalChars}">
							<span class="password-ctrl-icon validation-ico">
								<appIcons asset="close-circle" v-if="!noIdenticalChars" v-cloak />
								<appIcons asset="check-circle" v-if="noIdenticalChars" v-cloak /></span
							>Must not contain more than two identical characters in a row (aaa, 444).
						</li>
						<li :class="{'validation--passed': noIncrementingNumbers}">
							<span class="password-ctrl-icon validation-ico">
								<appIcons asset="close-circle" v-if="!noIncrementingNumbers" v-cloak />
								<appIcons asset="check-circle" v-if="noIncrementingNumbers" v-cloak /></span
							>Must not contain more than two incrementing numbers (12 is OK but 123 is not).
						</li>

						<li :class="{'validation--passed': noReservedWords}">
							<span class="password-ctrl-icon validation-ico">
								<appIcons asset="close-circle" v-if="!noReservedWords" v-cloak />
								<appIcons asset="check-circle" v-if="noReservedWords" v-cloak /></span
							>Must not contain your given names, username, email address, or the words ‘affinity’
							or 'pass’.
						</li>

						<li :class="{'validation--passed': notRepeatedPassword}">
							<span class="password-ctrl-icon validation-ico">
								<appIcons asset="close-circle" v-if="!notRepeatedPassword" v-cloak />
								<appIcons asset="check-circle" v-if="notRepeatedPassword" v-cloak /></span
							>Must not be a password that you have used previously.
						</li>
					</ul>
				</div>
			</div>
			<ul class="password-guide-list pwd-error" v-show="outFocusMessage !== '' && !showPwdGuide">
				<li><span class="password-ctrl-icon validation-ico"></span>{{ outFocusMessage }}</li>
			</ul>
		</div>
		<div class="form-row password-ctrl-input repwd">
			<input
				maxlength="50"
				v-bind:type="showRePwd ? 'text' : 'password'"
				v-model="output.confirmPassword"
				@keydown="handleEnterKey"
				placeholder="Re-enter your new password"
				autocomplete="off"
				class="long" />
			<span
				class="eye-toggle"
				:class="{
					'eye-toggle': true,
					'valid-pwd': hasConfirmed == true,
					'invalid-pwd': hasConfirmed == false,
				}"
				@mousedown="showRePwd = true"
				@mouseup="showRePwd = false"
				@mouseleave="showRePwd = false">
				<appIcons asset="eye-invisible" v-if="!showRePwd" />
				<appIcons asset="eye" v-if="showRePwd" />
			</span>
		</div>
		<div class="form-row" style="text-align: center">
			<div class="spinner small light" v-show="loading"></div>
			<button
				:disabled="!hasConfirmed || !output.passed"
				@click="handleSubmit"
				class="button large blue"
				v-show="!loading"
				v-cloak>
				Continue
			</button>
		</div>
	</div>
</template>
