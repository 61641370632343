<script>
	import Setup from "./Setup.vue";
	import {getErrorMessage} from "../remote/ajax";
	export default {
		name: "VerifyAnswer",
		props: ["redirectUrl", "sentToEmail", "email", "mobileNumber", "token", "questions"],
		components: {
			Setup,
		},
		data() {
			return {
				error: "",
				loading: false,
				questionList: [],
				data: {},
				userInfo: "",
			};
		},
		methods: {
			handleFormSubmit() {
				this.error = "";

				this.questionList.forEach((answer) => {
					if (answer.AnswerText === "") {
						this.error = "All answers must be filled out.";
					}
				});

				if (this.error != "") return;
				this.loading = true;
				const data = {
					Questions: this.questionList,
				};
				this.$ajax
					.post("/Password/VerifyAnswers", data, {
						headers: {
							Authorization: this.token,
						},
					})
					.then((resp) => {
						const error = getErrorMessage(resp.data.result, "QUESTIONS");
						if (error) {
							this.error = error;
							this.loading = false;
						} else if (resp.data.verified) {
							this.$router.push({
								name: "SetNew",
								params: {redirectUrl: this.redirectUrl},
								query: {token: resp.data.token},
							});
						}
					})
					.catch((error) => {
						const __this = this;
						if (error.result == "UNATHORIZED" || error.status === 401) {
							this.redirectTo(
								"The browser session has expired. Redirecting you to the Reset Pasword page...",
							);
						}
						this.loading = false;
					});
			},
			handleGoToOTP() {
				const params = {
					sentToEmail: this.sentToEmail,
					email: this.email,
					mobileNumber: this.mobileNumber,
					token: this.token,
				};
				this.$router.push({
					name: "OTP",
					params: params,
				});
			},
			populatePage() {
				this.loading = true;
				this.$ajax
					.get("/Password/GetSecurityQuestions", {
						headers: {
							Authorization: this.token,
						},
					})
					.then((resp) => {
						if (typeof resp.data != "undefined") {
							if (resp.data.error) {
								this.error = resp.data.errorMsg;
							} else if (resp.data.Questions.length) {
								this.questionList = resp.data.Questions;
								this.userInfo = `Welcome, ${resp.data.username}...`;
							}
						}
					})
					.catch(() => {})
					.then(() => {
						this.loading = false;
					});
			},
			redirectTo(message, page) {
				const router = this.$router;
				const name = page || "Recover";
				if (message) this.error = message;
				setTimeout(() => {
					router.push({
						name,
						query: this.redirectUrl ? {redirectUrl: this.redirectUrl} : undefined,
					});
				}, 3000);
			},
		},
		mounted() {
			if (this.token) {
				this.populatePage();
			} else {
				const {redirectUrl} = this.$route.query;
				this.$router.push({
					name: "Recover",
					query: redirectUrl ? {redirectUrl} : undefined,
				});
			}
		},
	};
</script>

<template>
	<setup title="Answer Security Questions" :error="error">
		<form
			class="card-body"
			method="post"
			id="form-questions-setup"
			@submit.prevent="handleFormSubmit">
			<div class="default-form w-border" style="margin: 0 auto; text-align: left">
				<div class="title-row section-title" style="text-align: center">
					{{ userInfo }}
				</div>

				<div
					class="form-row security-question"
					v-for="(question, index) in questionList"
					v-bind:key="question.id">
					<span>
						{{ question.Text }}
					</span>

					<input type="hidden" v-model="questionList[index].Id" />
					<input type="hidden" v-model="questionList[index].Text" />
					<input type="hidden" v-model="questionList[index].AnswerId" />

					<input type="text" v-model="questionList[index].AnswerText" class="long" />
				</div>

				<div class="form-row" style="text-align: center">
					<div class="spinner small light" v-show="loading"></div>
					<input
						type="submit"
						value="Continue"
						class="button large blue"
						v-show="!loading"
						v-cloak />
				</div>
				<div class="form-row" style="text-align: center" v-show="sentToEmail !== null" v-cloak>
					<button class="button large green" v-on:click="handleGoToOTP">Back to OTP</button>
				</div>
			</div>
		</form>
	</setup>
</template>
