<script>
import './styles/login.scss';
import './styles/styles.scss';


export default {
    name: "Setup",
    mounted() {},
};
</script>

<template>
    <router-view />
</template>
