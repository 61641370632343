<template>
	<setup
		title="Confirm Your Identity"
		:error="error"
		:success="success"
		:hidePageTitle="hidePageTitle">
		<div class="otp-sending-progress" v-cloak v-if="sendingOtp">
			<div>
				<span>Sending your code</span>
			</div>
		</div>
		<div class="otp-sending-progress" v-cloak v-if="verifyingOtp">
			<div>
				Checking that code.
				<span style="white-space: nowrap">Please wait a moment...</span>
			</div>
		</div>
		<div v-if="verifyingStatus">
			Verifying token...<span style="white-space: nowrap">Please wait</span>
		</div>
		<form
			class="card-body"
			method="post"
			@submit.prevent="handleFormSubmit"
			novalidate
			v-if="!verifyingStatus">
			<div style="text-align: center; padding: 20px 0 32px" v-if="initializing">
				<appIcons asset="loader" />
			</div>
			<div v-if="!initializing">
				<div class="otp-input-group" v-if="showOtpInput">
					<p>
						<strong>Enter your verification code here then click to verify:</strong>
					</p>

					<div class="form-row">
						<div class="otp-control">
							<v-otp-input
								ref="otpInput"
								input-classes="otp-input"
								separator=""
								:num-inputs="6"
								:should-auto-focus="true"
								:is-input-num="false"
								@on-change="handleOtpChange"
								@on-complete="handleOtpComplete" />
						</div>
					</div>
					<div class="form-row">
						<div class="spinner small light" v-show="verifyingOtp"></div>
						<input
							type="submit"
							value="VERIFY"
							class="button large blue"
							v-show="!verifyingOtp"
							v-cloak
							ref="submitBtn" />
					</div>
				</div>
				<div class="default-form w-border" style="text-align: center; margin: auto">
					<p v-if="!showOtpInput">Where would you like us to send your verification code?<br /></p>
					<p v-if="showOtpInput">Need a new verification code?<br /></p>
					<div class="otp-block-options">
						<div class="otp-option" v-for="list in otpChannels" v-bind:key="list.Channel">
							<input
								type="radio"
								v-on:change="handleContactChange(list.Channel)"
								:value="list.Channel"
								:name="list.Channel"
								:id="list.Channel"
								v-bind:checked="list.Channel == selectedChannel" />
							<label :for="list.Channel"
								>Send it to my {{ `${getOptionLabel(list.Channel)}` }}
								<span>{{ `(${list.Contact})` }}</span></label
							>
						</div>
					</div>
					<div class="otp-block-button">
						<button
							type="button"
							class="button small green"
							v-cloak
							ref="btnSendOTP"
							v-on:click="sendOtp"
							v-bind:disabled="otpSendingDisabled">
							<span v-show="!otpSending">{{
								`${showOtpInput ? "RESEND" : "SEND"}${timerCount > 0 ? ` (${timerCount})` : ""}`
							}}</span>
							<span class="spinner small light" v-show="otpSending"></span>
						</button>
					</div>
				</div>
			</div>
		</form>
	</setup>
</template>

<style scoped>
	.card-body p {
		font-size: 14px;
		font-weight: bold;
	}
	.otp-block-options {
		text-align: left;
		max-width: 360px;
		margin: 0 auto 12px;
	}

	.otp-option {
		position: relative;
		padding-left: 24px;
	}

	.otp-option > * {
		vertical-align: middle;
		line-height: 24px;
		font-size: 12px;
	}
	.otp-option input {
		position: absolute;
		left: 0;
		top: 5px;
		height: 13px;
		width: 13px;
		margin: 0 8px 0 0;
	}
	@media screen and (min-width: 560px) {
		.otp-option label {
			white-space: nowrap;
		}
	}
	@media screen and (max-width: 559px) {
		.otp-option span {
			white-space: nowrap;
		}
	}

	.otp-block-option {
		margin-bottom: 4px;
	}

	.otp-block-button {
		margin-bottom: 20px;
	}
	.otp-block-button > button {
		min-width: 120px;
		height: 32px !important;
		padding: 0 16px;
	}

	.otp-block-button > button:disabled {
		background-color: transparent !important;
		border-color: transparent !important;
	}

	.otp-block-button > button.green:disabled {
		background-color: #eee !important;
		border-color: #eee !important;
		color: #6a6a6a;
	}

	.otp-input-group {
		margin-bottom: 32px;
	}
</style>

<script>
	// import dayjs from "dayjs";
	import Setup from "./Setup.vue";
	import AppIcons from "./AppIcons.vue";

	export default {
		name: "MFA_V2",
		data() {
			return {
				sendingOtp: false,
				verifyingOtp: false,
				verifyingStatus: false,
				otpSending: false,
				selectedChannel: "",
				showOtpInput: false,
				code: "",
				token: "",
				error: "",
				success: "",
				hidePageTitle: false,
				page: undefined,
				redirectUrl: "",
				otpChannels: [],
				resendTimer: null,
				timerCount: 0,
				initializing: true,
			};
		},
		computed: {
			otpSendingDisabled() {
				return this.otpSending || this.timerCount > 0;
			},
		},
		methods: {
			getOptionLabel(channel) {
				switch (channel) {
					case "sms":
						return "mobile";
					case "email":
						return "email";
				}
			},
			redirectToLogin(message) {
				const router = this.$router;
				this.error = message;
				setTimeout(() => {
					router.push({
						name: "Login",
						query: this.redirectUrl ? {redirectUrl: this.redirectUrl} : undefined,
					});
				}, 5000);
			},
			async sendOtp() {
				if (!this.selectedChannel) {
					this.error = `No contact has been selected.`;
					return false;
				}
				this.error = "";
				const body = {
					Channel: this.selectedChannel,
				};

				this.otpRequest(this.selectedChannel);
			},
			otpRequest(Channel) {
				this.otpSending = true;
				this.$ajax
					.post(
						`/Mfa/request`,
						{Channel},
						{
							withCredentials: true,
						},
					)
					.then((resp) => {
						const {
							Success,
							Message,
							Resend: {IntervalSeconds},
						} = resp.data;
						this.otpSending = false;
						if (Success) {
							localStorage.setItem("MOUSSON_C", Channel);
							this.runResendTimer(IntervalSeconds);
							this.showOtpInput = true;
							this.success = Message;
							window.setTimeout(() => {
								this.success = "";
							}, 5000);
						}
					})
					.catch((err) => {
						if (err.status == 405) {
							const {
								Message,
								Resend: {RemainingIntervalSeconds},
							} = err.data;
							localStorage.setItem("MOUSSON_C", Channel);
							this.runResendTimer(RemainingIntervalSeconds);
							this.otpSending = false;
							this.error = Message;
							this.showOtpInput = true;
						} else this.handleErrorResponse(err.status, err.data.Message);
					});
			},
			handleContactChange(channel) {
				const {channel: qChannel} = this.$route.query;
				if (channel != this.selectedChannel) {
					this.selectedChannel = channel;
				}

				if (channel == qChannel) {
					this.selectedChannel = channel;
					this.showOtpInput = true;
				}
			},
			handleFormSubmit() {
				if (!this.code || this.code.length < 6) {
					this.error = "Please enter your verification code.";
					return false;
				}

				this.error = "";
				this.verifyingOtp = true;
				this.$ajax
					.post(
						`/Mfa/verify`,
						{Otp: this.code, Channel: this.selectedChannel},
						{withCredentials: true},
					)
					.then(async (resp) => {
						const {Success, RedirectPage, Message} = resp.data;
						if (Success) {
							// Clear the resend timer if it's running
							if (this.resendTimer) {
								clearInterval(this.resendTimer);
								this.resendTimer = null;
							}
							localStorage.removeItem("MOUSSON_C");
							this.success = Message;
							setTimeout(() => {
								this.$router.push({
									name: RedirectPage,
									params: {isMFAV2: true},
									query: this.redirectUrl ? {redirectUrl: this.redirectUrl} : undefined,
								});
							}, 3000);
						} else {
							this.error = Message;
							this.$refs.otpInput.clearInput();
						}
					})
					.catch((err) => {
						this.handleErrorResponse(err.status, err.data.Message);
					})
					.finally(() => {
						this.verifyingOtp = false;
					});
			},
			handleErrorResponse(status, message) {
				switch (status) {
					case 401:
						this.redirectToLogin(
							"That verification code has expired. Redirecting you to the login page...",
						);
						break;

					case 429:
						this.redirectToLogin(
							"You've reached the limit for incorrect code entries. Please ask your system administrator for help.",
						);
						break;

					default:
						this.error = message;
						break;
				}
			},
			handleOtpChange(value) {
				this.code = value;
			},
			handleOtpComplete(value) {
				this.code = value;
				setTimeout(() => {
					this.$refs.submitBtn.focus();
				}, 10);
			},
			runResendTimer(seconds) {
				this.timerCount = seconds > 0 ? seconds : 0;
				if (seconds > 0) {
					// Make sure to clear timer before it runs a new one
					if (this.resendTimer) {
						clearInterval(this.resendTimer);
						this.resendTimer = null;
					}

					this.resendTimer = setInterval(() => {
						this.timerCount--;
						if (this.timerCount <= 0) {
							clearInterval(this.resendTimer);
							this.resendTimer = null;
						}
					}, 1000);
				} else {
					localStorage.removeItem("MOUSSON_C");
					clearInterval(this.resendTimer);
					this.resendTimer = null;
				}
			},
		},
		components: {
			Setup,
			AppIcons,
		},
		async mounted() {
			const channel = localStorage.getItem("MOUSSON_C");
			if (channel) {
				this.showOtpInput = true;
				this.selectedChannel = channel;

				const {Resend} = await this.$ajax
					.get(`/Mfa/status?channel=${channel}`)
					.then((resp) => resp.data);
				this.timerCount = Resend ? Resend.RemainingIntervalSeconds : 0;
				this.runResendTimer(this.timerCount);
			}
			const {Contacts} = await this.$ajax.get("/MFA/contact").then((resp) => resp.data);
			this.otpChannels = Contacts;
			this.redirectUrl = this.$route.query.redirectUrl;
			this.initializing = false;
		},
	};
</script>
