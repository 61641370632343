import Vue from "vue";
import VueRouter from "vue-router";
import Login from "../views/Login.vue";
import Emails from "../views/Emails.vue";
import Recover from "../views/Recover.vue";
import SetupPassword from "../views/SetupPassword.vue";
import SetupQuestions from "../views/SetupQuestions.vue";
import VerifyAnswer from "../views/VerifyAnswer.vue";
import SetNew from "../views/SetNew.vue";
import OTP from "../views/OTP";
import MFA from "../views/MFA";
import Identity from "../views/Identity";
import MFA_V2 from "../views/MFAv2";

Vue.use(VueRouter);

const routes = [
    {
        path: "/",
        name: "Login",
        component: Login,
        props: true,
        meta: {
            title: "Login",
        },
    },
    {
        path: "/Emails",
        name: "Emails",
        component: Emails,
        meta: {
            title: "Emails",
        },
    },
    {
        path: "/Recover",
        name: "Recover",
        component: Recover,
        meta: {
            title: "Forgot Password",
        },
    },
    {
        path: "/SetupPassword",
        name: "SetupPassword",
        component: SetupPassword,
        meta: {
            title: "Reset Password",
        },
    },
    {
        path: "/SetupQuestions",
        name: "SetupQuestions",
        component: SetupQuestions,
        props: true,
        meta: {
            title: "Answer Security Password",
        },
    },
    {
        path: "/VerifyAnswer",
        name: "VerifyAnswer",
        component: VerifyAnswer,
        props: true,
        meta: {
            title: "Answer Security Password",
        },
    },
    {
        path: "/SetNew",
        name: "SetNew",
        component: SetNew,
        props: true,
        meta: {
            title: "Reset Password",
        },
    },
    {
        path: "/Otp",
        name: "OTP",
        component: OTP,
        props: true,
        meta: {
            title: "One Time Password",
        },
    },
    {
        path: "/mfa",
        name: "MFA",
        component: MFA,
        props: true,
        meta: {
            title: "Multi-Factor Authentication",
        },
    },
    {
        path: "/identity",
        name: "Identity",
        component: Identity,
        props: true,
        meta: {
            title: "User Identity",
        },
    },
    {
        path: "/mfa-v2",
        name: "MFA_V2",
        component: MFA_V2,
        props: true,
        meta: {
            title: "User Identity",
        },
    },
];

const router = new VueRouter({
    mode: "history",
    base: process.env.BASE_URL,
    routes,
});

export default router;
