import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import VTooltip from "v-tooltip";
import Ajax from "./remote/ajax";
import OtpInput from "@bachdgvn/vue-otp-input";

import "./styles/app.scss";

Vue.component("v-otp-input", OtpInput);
Vue.use(Ajax, {
    handleUnautorized: true,
});
Vue.use(VTooltip);

Vue.prototype.$validateEmail = function (email) {
    var re =
        /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
};

Vue.prototype.$validUrl = function (string) {
    if (typeof URL == "function") {
        let url;
        try {
            url = new URL(string);
        } catch (_) {
            return false;
        }
        return url.protocol === "http:" || url.protocol === "https:";
    }

    var pattern = new RegExp(
        "^(https?:\\/\\/)?" + // protocol
            "((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|" + // domain name
            "((\\d{1,3}\\.){3}\\d{1,3}))" + // OR ip (v4) address
            "(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*" + // port and path
            "(\\?[;&a-z\\d%_.~+=-]*)?" + // query string
            "(\\#[-a-z\\d_]*)?$", // fragment locator
        "i"
    );
    return !!pattern.test(string);
};

Vue.prototype.$checkMFACookie = async function (
    redirectUrl = "",
    page = undefined
) {
    const res = await Vue.prototype.$ajax.post(
        `${Vue.prototype.$apiUrl}/api/Auth`,
        null,
        {
            withCredentials: true,
            params: { redirectUrl, page },
        }
    );
    if (res.status == 200) {
        return res.data;
    } else {
        return res;
    }
};

router.afterEach((to, from) => {
    Vue.nextTick(() => {
        document.title = to.meta.title || "Login";
    });
});
caches;

Vue.prototype.$apiUrl = process.env.VUE_APP_API_URL;

Vue.config.productionTip = false;

new Vue({
    router,
    render: (h) => h(App),
}).$mount("#app");
