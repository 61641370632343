<template>
	<setup title="Reset Your Password" :error="error" :hidePageTitle="hidePageTitle">
		<form class="card-body" method="post" @submit.prevent="handleFormSubmit">
			<div class="default-form w-border" style="text-align: center; margin: auto">
				<div class="form-row">
					<input
						type="text"
						v-model="userName"
						v-on:input="checkEmail"
						autocomplete="off"
						placeholder="Username or email"
						class="long"
						v-show="!hideInput"
						v-cloak />
				</div>
				<div class="form-row label" v-if="showEmailUsers" v-cloak>
					<select
						id="emailuser"
						v-model="emailUser"
						name="emailuser"
						style="width: 500px; box-sizing: content-box">
						<option value="null" disabled selected>Username and company</option>
						<option
							v-for="user in availableEmailUsers"
							v-bind:value="user.UserName"
							:key="user.EmployeeNumber">
							{{ user.UserName }}, {{ user.CompanyName }} ({{ user.CompanyNumber }})
						</option>
					</select>
				</div>
				<div class="mt-4" style="text-align: center">
					<div class="spinner small light" v-show="loading"></div>
					<input
						type="submit"
						value="Continue"
						class="button large blue"
						v-show="!loading"
						v-cloak />
				</div>
			</div>
		</form>
	</setup>
</template>

<script>
	import Setup from "./Setup.vue";
	export default {
		name: "Recover",
		data() {
			return {
				error: "",
				loading: false,
				userName: "",
				redirectUrl: "",
				availableEmailUsers: null,
				instructionsMessage: "",
				hideInput: false,
				emailUser: null,
				hidePageTitle: false,
			};
		},
		components: {
			Setup,
		},
		computed: {
			showEmailUsers() {
				return this.availableEmailUsers && this.availableEmailUsers.length > 1;
			},
		},
		methods: {
			handleFormSubmit() {
				let data = {
					redirectUrl: this.redirectUrl,
					userName: this.userName,
					emailUser: this.emailUser,
					targetPath: this.$route.path,
				};
				this.error = "";
				if (data.userName == "") {
					this.error = "Username cannot be blank.";
				}
				if (this.error != "") return;

				this.loading = true;

				this.$ajax
					.post("/Password/Recover", data)
					.then((resp) => {
						if (resp.data) {
							if (resp.data.Error) {
								this.error = resp.data.Error;
								window.setTimeout(() => {
									this.$router.push({
										name: "Login",
										query: this.redirectUrl ? {redirectUrl: this.redirectUrl} : undefined,
									});
								}, 5000);
							} else {
								const sentToEmail = resp.data.email ? true : resp.data.mobileNumber ? false : null;
								const {hasCompleteAnswers, IsAllowedForNewAuth, RedirectPage} = resp.data;

								if (IsAllowedForNewAuth) {
									localStorage.removeItem("MOUSSON_C");

									this.$router.push({
										name: RedirectPage,
										query: this.redirectUrl ? {redirectUrl: this.redirectUrl} : undefined,
									});
								} else {
									if (!hasCompleteAnswers && sentToEmail === null) {
										this.$router.push({
											name: "Login",
											params: {
												errorMsg:
													"Your email, phone number and security questions are not set up. Please contact your admin to reset your password.",
											},
											query: this.redirectUrl ? {redirectUrl: this.redirectUrl} : undefined,
										});
									} else {
										const params = {
											sentToEmail: sentToEmail,
											email: resp.data.email,
											mobileNumber: resp.data.mobileNumber,
											token: resp.data.token,
										};
										this.$router.push({
											name: sentToEmail == null ? "VerifyAnswer" : "OTP",
											params: params,
										});
									}
								}
							}
						}
					})
					.catch((err) => {
						if (err.status == 401) {
							this.$router.push({
								name: "Login",
								query: this.redirectUrl ? {redirectUrl: this.redirectUrl} : undefined,
							});
						}
					})
					.finally(() => {
						this.loading = false;
					});
			},
			validateEmail(email) {
				var re =
					/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
				return re.test(String(email).toLowerCase());
			},
			checkEmail() {
				let emailInput = this.userName;
				if (emailInput && this.validateEmail(emailInput)) {
					this.loading = true;
					this.$ajax
						.get("/auth/CheckEmail", {
							params: {
								email: emailInput,
							},
						})
						.then((resp) => {
							const {AllowedForNewAuth, Users, EmailAddress} = resp.data;
							if (this.userName == EmailAddress) {
								this.availableEmailUsers = !AllowedForNewAuth ? Users : null;
							}
							this.error = "";
						})
						.catch()
						.then(() => {
							this.loading = false;
						});
				}
			},
			verifyToken(token, userId) {
				this.hideInput = true;
				this.loading = true;
				this.hidePageTitle = true;
				let data = {
					token: token,
					userId: userId,
					redirectUrl: this.redirectUrl,
				};
				this.$ajax
					.post("/Password/VerifyToken", data)
					.then((resp) => {
						if (typeof resp.data != "undefined") {
							if (resp.data.Error != null) {
								this.error = resp.data.Error;
							} else if (resp.data.RedirectPage != null) {
								this.instructionsMessage = resp.data.InstructionsMessage;
								this.$router.push({
									name: resp.data.RedirectPage,
									query: this.redirectUrl ? {redirectUrl: this.redirectUrl} : undefined,
								});
							}
						}
					})
					.catch(() => {})
					.then(() => {
						this.loading = false;
					});
			},
		},
		mounted() {
			if (typeof this.$route.query) {
				this.redirectUrl = this.$route.query.redirectUrl || "";

				if (
					typeof this.$route.query.token != "undefined" &&
					typeof this.$route.query.userId != "undefined"
				) {
					this.verifyToken(this.$route.query.token, this.$route.query.userId);
				}
			}
		},
	};
</script>
